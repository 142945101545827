/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

import '../styles/styles.scss'
import style from './layout.module.scss'

interface Props {
  location?: Location
  title?: string
  children?: any
}

const Layout = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query {

      site {
        siteMetadata {
          title
        }
      },

      backgroundImage: file(
        relativePath: { eq: "background1.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 2048,
            quality: 100,
            srcSetBreakpoints: [540, 720, 900, 1080, 1296, 1512, 1728, 1944, 2048],
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)

  return (
    <>
      <div className={style.backgroundImage}>
        <Img
          fluid={data.backgroundImage.childImageSharp.fluid}
          style={{
            height: '100%',
          }}
        />
      </div>

      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <div className={style.mainContentWrapper}>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
